import {DynamicConf, Modalbox} from '@parship/psg-interfaces';

class PmgNoneEuCookiebanner {
	private dynamicConf: DynamicConf;
	private modalBox: Modalbox;

	constructor() {
		this.dynamicConf = window.dynamicConf;
		this.setCookieIfUserIsAlreadyRegistered();

		if (this.getCookie('tms_infobanner') === null) {
			this.createCookieBanner();
			this.trackEvent('dialog', 'show');
		}

		if (this.dynamicConf.tfvCookieText) {
			document
				.querySelector('a.js-stopTracking')
				?.addEventListener('click', (event: Event) => {
					event.preventDefault();
					this.stopTracking();
				});
		}
	}

	private stopTracking(): void {
		this.setCookie('tfvEnabled', 'true', 2000);
		this.showModalBox();
	}

	private showModalBox(): void {
		this.modalBox = document.querySelector('.tfvInfoModal');

		if (this.modalBox) {
			this.modalBox.showPopover();
			return;
		}

		const wrapper: HTMLDivElement = document.createElement('div');
		wrapper.classList.add('tfvInfoModal');
		wrapper.id ='tfvInfoModal';
		wrapper.setAttribute('popover', '');
		wrapper.innerHTML = `
				<p>${this.dynamicConf.tfvCookieText}</p>
				<button class="wp-block-button" popovertarget="tfvInfoModal" popovertargetaction="hide">
					<span class="wp-block-button__link">OK</span>
				</button>
		`;
		wrapper.style.padding = '20px';
		wrapper.style.backgroundColor = 'white';
		wrapper.style.border = 'none';
		wrapper.style.textAlign = 'center';

		document.body.appendChild(wrapper);

		this.modalBox = document.querySelector('.tfvInfoModal');
		this.modalBox.showPopover();
	}

	private createCookieBanner(): void {
		const cookieBanner: HTMLElement = document.createElement('div');
		cookieBanner.setAttribute('id', 'pegCookiebanner');
		cookieBanner.classList.add('optOut');
		cookieBanner.innerHTML = `<span>${this.dynamicConf.cookiebanner.bannerText}</span><a href="#" class="js-optOutAllow">${this.dynamicConf.cookiebanner.okButtonText}</a>`;

		document.body.appendChild(cookieBanner);
		document.querySelector('.js-optOutAllow').addEventListener('click', (event) => {
			event.preventDefault();
			cookieBanner.remove();
			this.setCookie('tms_infobanner', 'dismiss', 760);
			this.trackEvent('ok_button', 'click');
		});
	}

	private setCookieIfUserIsAlreadyRegistered(): void {
		const domePageVars: HTMLElement = document.getElementById('domPageVars');
		const hasUserId: boolean =
			domePageVars && domePageVars.getAttribute('data-user-id') !== null;

		if (hasUserId === true) {
			this.setCookie('tms_infobanner', 'dismiss', 760);
		}
	}

	private setCookie(name: string, value: string, days: number): void {
		let expires: string = '';
		if (days) {
			const date: Date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = '; expires=' + date.toUTCString();
		}

		document.cookie =
			name +
			'=' +
			(value || '') +
			expires +
			'; path=/; domain=' +
			this.getMainDomain(document.location.host);
	}

	private getCookie(name: string): string | null {
		const nameEQ: string = name + '=';
		const ca: string[] = document.cookie.split(';');

		for (let i: number = 0; i < ca.length; i++) {
			let c: string = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1, c.length);
			}
			if (c.indexOf(nameEQ) === 0) {
				return c.substring(nameEQ.length, c.length);
			}
		}

		return null;
	}

	private getMainDomain(fullDomain: string): string {
		const pattern: RegExp = /^.*?((\.?[^.]+?)(\.([^.]+?)\.[^.]+?))$/;
		const result: RegExpExecArray = pattern.exec(fullDomain);

		if (!result) {
			return fullDomain;
		}

		const secondLevelDomainPart: string = result[4];
		let secondLevelDomain: string = result[3];
		if (secondLevelDomainPart === 'co' || secondLevelDomainPart === 'com') {
			secondLevelDomain = result[1];
		}
		return secondLevelDomain;
	}

	private trackEvent(targetId: string, action: string): void {
		const customEvent: CustomEvent = new CustomEvent('pegLogger-trackingPermission', {
			detail: {
				category: 'tracking_choice',
				subcategory: 'tracking_choice_dialog',
				targetId,
				action,
			},
		});

		document.dispatchEvent(customEvent);
	}
}

const initCookieBanner = () => {
	if (window.dynamicConf?.cookiebanner && !window.location.search.includes('embedded')) {
		new PmgNoneEuCookiebanner();
	} else {
		console.log('no cookiebanner')
	}
};

if (/complete|interactive|loaded/.test(document.readyState)) {
	initCookieBanner();
} else {
	document.addEventListener('DOMContentLoaded', () => initCookieBanner());
}
